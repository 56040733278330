<template>
  <information-panel restricted>
    <template v-slot:header>
      <v-icon>mdi-text</v-icon>
      <p class="text-h6 font-weight-bold ml-3 mb-0">
        Description
      </p>
    </template>
    <template v-slot:content>
      <p class="text--secondary font-weight-bold">
        Created by
        <router-link to="/profile" class="text-decoration-none font-weight-bold">
          {{creator}}
        </router-link>
      </p>
      <!-- eslint-disable max-len -->
      <p class="text--secondary mb-0">
        {{about}}
      </p>
      <br>
      <p class="text--secondary font-weight-bold">
        Tags:
      </p>
        <v-chip
          class="ma-1"
          v-for="tag in tags"
          :key="tag"
        >
        {{tag}}
        </v-chip>
    </template>
  </information-panel>
</template>

<script>
export default {
  name: 'NFTListingDescription',
  props: {
    about: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    creator: {
      type: String,
      required: true,
    },
  },
};
</script>
